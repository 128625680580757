<template>
    <section>
        <div class="row no-print">
            <b-col>
                <b-form-group class="form-group">
                    <label for="">Período</label>
                    <Periods @select="handlePeriod" :monthSelected="monthSelected" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="form-group">
                    <label for="status">Situação</label>
                    <v-autocomplete placeholder="Todas as situações" :options="situationsOptions"
                        v-model="filter.status" />

                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="form-group">
                    <label for="status">Tipo de item</label>
                    <v-autocomplete placeholder="Todos os tipos" :options="itemTypeOptions" v-model="filter.itemType" />

                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="form-group">
                    <label for="search">Paciente / Item</label>
                    <div class="input-wrapper">
                        <Search class="icon" />
                        <b-form-input autocomplete="off" id="search" v-model="filter.query" class="form-control"
                            placeholder="Pesquisar paciente ou item" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group id="health_plan">
                    <label for="health_plan" id="input-title">Convênio</label>
                    <v-autocomplete placeholder="Todos os convênios" :options="healthPlans"
                        v-model="filter.healthPlan" />
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group>
                    <label for="professional">Executante</label>
                    <multiselect class="with-border" :options="professionals" :value="professional" label="label"
                        @search-change="getProfessionalsAuditDebounce" @select="selectProfessional" :show-labels="false"
                        id="professional" placeholder="Selecionar">
                        <template slot="caret">
                            <div class="chevron">
                                <ChevronDown />
                            </div>
                        </template>
                        <template slot="noOptions">
                            Digite para pesquisar um profissional
                        </template>
                        <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                </b-form-group>
            </b-col>

        </div>
    </section>
</template>
<script>
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses';
import { getCurrentClinic } from '@/utils/localStorageManager';
import debounce from 'lodash/debounce';
export default {
    components: {
        'v-autocomplete': () => import('@/components/Autocomplete'),
        Search: () => import('@/assets/icons/search.svg'),
        Periods: () => import('@/components/General/Periods'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg')
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            query: '',
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
            filter: {
                healthPlan: null,
                professional: null,
                start: null,
                end: null,
                query: '',
                status: null,
                itemType: null
            },
            healthPlans: [],
            professionals: [],
            monthSelected: false,
            professional: null,
            status_payments: [
                { label: 'Vencido', value: 'expired' },
                { label: 'Pago', value: 'paid_out' },
                { label: 'Pendente', value: 'pending' },
                { label: 'Parcelado', value: 'installments' }
            ],
            itemTypeOptions: [
                { label: 'Procedimento', value: 'PROCEDURE' },
                { label: 'Agendamento', value: 'APPOINTMENT' },
                { label: 'Exame', value: 'EXAM' },
                { label: 'Centro cirúrgico', value: 'SURGICAL' },
                { label: 'Retorno', value: 'RETURN' },
                { label: 'Telemedicina', value: 'TELEMEDICINE' },
                { label: 'Despesa', value: 'OTHER_EXPENSES' },
                { label: 'Produto', value: 'PRODUCT' }
            ],
            situations: [
                { label: TissGuide.GUIDE_SITUATION_IN_PREPARATION, value: TissGuide.GUIDE_SITUATION_IN_PREPARATION },
                { label: TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION, value: TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION },
                { label: TissGuide.GUIDE_SITUATION_AUTHORIZED, value: TissGuide.GUIDE_SITUATION_AUTHORIZED },
                { label: TissGuide.GUIDE_SITUATION_DENIED, value: TissGuide.GUIDE_SITUATION_DENIED },
                { label: TissGuide.GUIDE_SITUATION_CANCELED, value: TissGuide.GUIDE_SITUATION_CANCELED },
                { label: TissGuide.GUIDE_SITUATION_SENT, value: TissGuide.GUIDE_SITUATION_SENT },
                { label: TissGuide.GUIDE_SITUATION_OPEN, value: TissGuide.GUIDE_SITUATION_OPEN },
                { label: TissGuide.GUIDE_SITUATION_PAYED, value: TissGuide.GUIDE_SITUATION_PAYED },
                { label: TissGuide.GUIDE_SITUATION_PARTIALLY_PAID, value: TissGuide.GUIDE_SITUATION_PARTIALLY_PAID },
                { label: TissGuide.GUIDE_SITUATION_GLOSSED, value: TissGuide.GUIDE_SITUATION_GLOSSED }
            ]
        };
    },
    computed: {
        situationsOptions() {
            return [
                ...this.status_payments,
                ...this.situations
            ];
        }
    },
    created() {
        this.getProfessionalsAuditDebounce = debounce(
            this.getProfessionalsAudit,
            500
        );
    },
    mounted() {
        const now = { start: this.moment().format('YYYY-MM-DD'), end: this.moment().format('YYYY-MM-DD') };
        this.handlePeriod(now);
        this.monthSelected = true;
        this.getClinicHealthPlans();
    },
    methods: {
        resetChevronState() {
            if (this.chevronState === true) {
                const chevron = document.getElementById('chevron');
                chevron.classList.toggle('chevron-active');
                this.chevronState = false;
            }
        },
        handlePeriod(value) {
            this.filter.start = value.start;
            this.filter.end = value.end;
        },
        async getClinicHealthPlans() {
            try {
                const { data } = await this.api.getAllClinicHealthPlans(this.clinic.id);

                this.healthPlans = data.map(clinicHealthPlan => ({
                    label: clinicHealthPlan?.health_plan?.fantasy_name,
                    value: clinicHealthPlan?.health_plan?.id
                })).sort((a, b) => {
                    if (a.label < b.label) return -1;
                    else return 1;
                });

            } catch (error) {
                console.error(error);
            }
        },
        selectProfessional(professional) {
            this.professional = professional;
            this.filter.professional = professional.label;
            if (!professional.value) {
                this.filters.actionDescription = null;
            }
        },
        async getProfessionalsAudit(query) {
            if (query.length > 2) {
                try {
                    const response = await this.api.getProfessionalAudit(
                        this.clinic.id,
                        query
                    );
                    const professionals = response.data.map(audits => {
                        return audits.person;
                    });
                    this.professionals = professionals.map(professional => {
                        return {
                            label: professional.name,
                            value: professional.id
                        };
                    });
                    this.professionals.unshift({
                        label: 'Selecionar',
                        value: null
                    });
                } catch (error) {
                    this.$toast.error(error.message);
                }
            }
        }

    },
    watch: {
        filter: {
            handler() {
                this.$emit('changeFilter', this.filter);
            },
            deep: true
        }
    }
};
</script>
<style lang="scss" scoped>
.icon {
    width: 20px;
    height: 20px;
}

.input-wrapper {
    position: relative;
}

.input-wrapper .icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.input-wrapper .form-control {
    padding-left: 35px;
}
</style>